import React from 'react';

export const Info = () => {
  return (
    <svg
      width="9"
      height="19"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19141 3.4761H4.7801V6.90533H4.19141V3.4761ZM4.19141 2.17969H4.7801V2.83594H4.19141V2.17969Z"
        fill="#34FF61"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 8.08215C6.44924 8.08215 8.02941 6.50197 8.02941 4.55273C8.02941 2.60349 6.44924 1.02332 4.5 1.02332C2.55076 1.02332 0.970588 2.60349 0.970588 4.55273C0.970588 6.50197 2.55076 8.08215 4.5 8.08215ZM4.5 8.55273C6.70914 8.55273 8.5 6.76187 8.5 4.55273C8.5 2.3436 6.70914 0.552734 4.5 0.552734C2.29086 0.552734 0.5 2.3436 0.5 4.55273C0.5 6.76187 2.29086 8.55273 4.5 8.55273Z"
        fill="#34FF61"
      />
    </svg>
  );
};
