import React from 'react';

export const CommonAvatarsProfile = () => {
  return (
    <svg
      width="58"
      height="59"
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23 24V26H28.7H34.4V24" stroke="white" strokeWidth="1.7" />
      <path d="M23 24V26H28.7H34.4V24" stroke="white" strokeWidth="1.7" />
      <circle cx="34.5" cy="16.5" r="2.15" stroke="white" strokeWidth="1.7" />
      <circle cx="22.5" cy="16.5" r="2.15" stroke="white" strokeWidth="1.7" />
      <path
        d="M56.4091 56.0462V56.0462C56.4091 45.4021 47.7804 36.7734 37.1364 36.7734H28.7045H20.2727C9.62869 36.7734 1 45.4021 1 56.0462V56.0462"
        stroke="white"
        strokeWidth="1.68636"
        strokeLinecap="round"
      />
      <rect
        x="16.8432"
        y="44.8432"
        width="24.8136"
        height="12.7682"
        rx="1.56591"
        stroke="white"
        strokeWidth="1.68636"
      />
      <mask id="path-6-inside-1_4012_70017" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3137 0.5C20.8579 0.5 14.8137 6.54416 14.8137 14V15.0493C13.7794 15.2556 13 16.1685 13 17.2635V20.0007C13 21.1323 13.8324 22.0694 14.9183 22.2332C15.3973 26.0733 17.4892 29.4124 20.4946 31.5514V37.5H36.1946V31.5071C39.1619 29.3702 41.2264 26.0582 41.7067 22.2527C42.8769 22.1684 43.8 21.1924 43.8 20.0007V17.2635C43.8 16.1084 42.9326 15.1559 41.8137 15.0218V14C41.8137 6.54416 35.7696 0.5 28.3137 0.5Z"
        />
      </mask>
      <path
        d="M14.8137 15.0493L15.1463 16.7164L16.5137 16.4437V15.0493H14.8137ZM14.9183 22.2332L16.6052 22.0228L16.4457 20.7444L15.1718 20.5522L14.9183 22.2332ZM20.4946 31.5514H22.1946V30.6747L21.4804 30.1663L20.4946 31.5514ZM20.4946 37.5H18.7946V39.2H20.4946V37.5ZM36.1946 37.5V39.2H37.8946V37.5H36.1946ZM36.1946 31.5071L35.2011 30.1276L34.4946 30.6365V31.5071H36.1946ZM41.7067 22.2527L41.5846 20.5571L40.1946 20.6572L40.0201 22.0398L41.7067 22.2527ZM41.8137 15.0218H40.1137V16.5302L41.6114 16.7097L41.8137 15.0218ZM16.5137 14C16.5137 7.48304 21.7968 2.2 28.3137 2.2V-1.2C19.919 -1.2 13.1137 5.60527 13.1137 14H16.5137ZM16.5137 15.0493V14H13.1137V15.0493H16.5137ZM14.7 17.2635C14.7 16.9949 14.8915 16.7673 15.1463 16.7164L14.4812 13.3821C12.6673 13.7439 11.3 15.3421 11.3 17.2635H14.7ZM14.7 20.0007V17.2635H11.3V20.0007H14.7ZM15.1718 20.5522C14.905 20.512 14.7 20.2788 14.7 20.0007H11.3C11.3 21.9858 12.7597 23.6269 14.6647 23.9142L15.1718 20.5522ZM21.4804 30.1663C18.8496 28.294 17.0233 25.3745 16.6052 22.0228L13.2313 22.4437C13.7713 26.7721 16.1288 30.5308 19.5089 32.9364L21.4804 30.1663ZM22.1946 37.5V31.5514H18.7946V37.5H22.1946ZM36.1946 35.8H20.4946V39.2H36.1946V35.8ZM34.4946 31.5071V37.5H37.8946V31.5071H34.4946ZM40.0201 22.0398C39.6009 25.3614 37.7985 28.2571 35.2011 30.1276L37.1881 32.8866C40.5252 30.4833 42.8519 26.755 43.3934 22.4656L40.0201 22.0398ZM42.1 20.0007C42.1 20.2944 41.872 20.5364 41.5846 20.5571L41.8289 23.9483C43.8818 23.8004 45.5 22.0903 45.5 20.0007H42.1ZM42.1 17.2635V20.0007H45.5V17.2635H42.1ZM41.6114 16.7097C41.8861 16.7426 42.1 16.9793 42.1 17.2635H45.5C45.5 15.2375 43.9792 13.5692 42.0161 13.3339L41.6114 16.7097ZM40.1137 14V15.0218H43.5137V14H40.1137ZM28.3137 2.2C34.8307 2.2 40.1137 7.48304 40.1137 14H43.5137C43.5137 5.60528 36.7085 -1.2 28.3137 -1.2V2.2Z"
        fill="white"
        mask="url(#path-6-inside-1_4012_70017)"
      />
    </svg>
  );
};
