import React from 'react';
import { WHITE } from '@global/styles/variables';

export const HomeIcon = ({ fill = WHITE }: { fill?: string }) => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1818 25.0007H6.8182C6.64241 25.0007 6.5 24.8584 6.5 24.6825V23.0916C6.5 22.9159 6.64224 22.7734 6.8182 22.7734H27.1818C27.3576 22.7734 27.5 22.9157 27.5 23.0916V24.6825C27.5 24.8582 27.3576 25.0007 27.1818 25.0007ZM7.13618 24.3642H26.8634V23.4096H7.13618V24.3642Z"
        fill={fill}
      />
      <path
        d="M26.0095 23.4091H7.99008C7.81429 23.4091 7.67188 23.2669 7.67188 23.0909V13.138C7.67188 12.4361 8.2426 11.8652 8.94467 11.8652H25.0548C25.7567 11.8652 26.3276 12.436 26.3276 13.138V23.0909C26.3276 23.2669 26.1852 23.4091 26.0094 23.4091H26.0095ZM8.30806 22.7729H25.6911V13.138C25.6911 12.7871 25.4055 12.5016 25.0546 12.5016H8.94448C8.59362 12.5016 8.30806 12.7871 8.30806 13.138V22.7729Z"
        fill={fill}
      />
      <path
        d="M24.4192 16.5497H9.58187C9.40608 16.5497 9.26367 16.4074 9.26367 16.2315V13.7752C9.26367 13.5994 9.40591 13.457 9.58187 13.457H24.4196C24.5954 13.457 24.7378 13.5993 24.7378 13.7752V16.2315C24.7377 16.4074 24.5954 16.5497 24.4195 16.5497H24.4192ZM9.89985 15.9132H24.1012V14.0933L9.89985 14.0934V15.9132Z"
        fill={fill}
      />
      <path
        d="M21.2967 23.4085H12.703C12.5272 23.4085 12.3848 23.2662 12.3848 23.0903V18.4256C12.3848 18.2498 12.527 18.1074 12.703 18.1074H21.2967C21.4725 18.1074 21.6149 18.2497 21.6149 18.4256L21.6147 23.0903C21.6147 23.2662 21.4725 23.4085 21.2965 23.4085H21.2967ZM13.0209 22.7722H20.9783V18.7439H13.0209V22.7722Z"
        fill={fill}
      />
      <path
        d="M16.9998 23.4085C16.8241 23.4085 16.6816 23.2662 16.6816 23.0903V18.4256C16.6816 18.2498 16.8239 18.1074 16.9998 18.1074C17.1756 18.1074 17.318 18.2497 17.318 18.4256V23.0903C17.318 23.2662 17.1758 23.4085 16.9998 23.4085Z"
        fill={fill}
      />
      <path
        d="M18.3922 8.34786C18.3403 8.34786 18.2878 8.33519 18.2393 8.30842C18.0851 8.22383 18.0289 8.03055 18.1138 7.87654L19.4543 5.43639C19.5389 5.2822 19.7327 5.22634 19.8862 5.31093C20.0404 5.39553 20.0966 5.5888 20.0116 5.74282L18.6712 8.18296C18.6133 8.28826 18.5047 8.34786 18.3922 8.34786L18.3922 8.34786Z"
        fill={fill}
      />
      <path
        d="M16.9998 12.5016C16.8241 12.5016 16.6816 12.3593 16.6816 12.1834V10.9871C16.6816 10.6559 16.7659 10.3287 16.9249 10.0409L17.5127 8.96631C17.597 8.81212 17.7906 8.75537 17.9446 8.84014C18.0988 8.92438 18.1552 9.11765 18.0708 9.27202L17.4822 10.3475C17.3746 10.5425 17.318 10.7635 17.318 10.9871V12.1833C17.318 12.3593 17.1758 12.5015 16.9998 12.5015L16.9998 12.5016Z"
        fill={fill}
      />
      <path
        d="M20.0893 10.1406C19.9877 10.1406 19.881 10.1347 19.7695 10.1229C19.1267 10.0551 18.3703 9.79707 17.64 9.39643C16.9138 8.99934 16.2915 8.50037 15.8884 7.99192C15.4269 7.40959 15.2968 6.86276 15.5217 6.45158C16.0122 5.56534 17.7548 5.89886 19.1457 6.66199C20.5347 7.42762 21.749 8.71969 21.2639 9.60611V9.60647C21.0728 9.95572 20.6611 10.1406 20.0893 10.1406L20.0893 10.1406ZM16.6906 6.55509C16.3681 6.55509 16.1498 6.63094 16.0793 6.75854C15.9899 6.92202 16.1075 7.2436 16.3872 7.59663C16.7386 8.03975 17.292 8.48058 17.9457 8.83803C18.6041 9.19943 19.2753 9.43092 19.8366 9.49031C20.2824 9.53635 20.6156 9.46514 20.706 9.30114C20.8863 8.97151 20.2486 7.99673 18.8391 7.21986C17.9743 6.74495 17.2015 6.55507 16.6906 6.55507L16.6906 6.55509ZM20.9852 9.45371H20.9882H20.9852Z"
        fill={fill}
      />
      <path
        d="M20.0603 5.99168C19.5111 5.99168 19.0645 5.54481 19.0645 4.99584C19.0643 4.44687 19.5111 4 20.0603 4C20.6096 4 21.0565 4.44687 21.0565 4.99584C21.0565 5.545 20.6096 5.99168 20.0603 5.99168ZM20.0603 4.63642C19.862 4.63642 19.7009 4.79775 19.7009 4.99585C19.7009 5.19412 19.8622 5.35528 20.0603 5.35528C20.2589 5.35528 20.4201 5.19395 20.4201 4.99585C20.4201 4.79758 20.2587 4.63642 20.0603 4.63642Z"
        fill={fill}
      />
    </svg>
  );
};
