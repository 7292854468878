const levels = [
  100, 1000, 2044, 3255, 4659, 6289, 8179, 10372, 12916, 15866, 19289, 23259,
  27865, 33207, 39404, 46593, 54932, 64605, 75826, 88842, 103941, 121456,
  141773, 165341, 192679, 224392, 261179, 303852, 353352, 410772, 477380,
  554645, 644272, 748240, 868842, 1008741, 1171024, 1359272, 1577639, 1830946,
  2124781, 2465630, 2861015, 3319661, 3851691, 4468846, 5184745, 6015189,
  6978503, 8095948, 9392183, 10895817, 12640032, 14663321, 17010336, 19732874,
  22891018, 26554465, 30804063, 35733597, 41451857, 48085038, 55779529,
  64705137, 75058843, 87069142, 101001089, 117162148, 135908975, 157655295,
  182881027, 212142875, 246086619, 285461362, 331136064, 384118719, 445578598,
  516872058, 599572471, 695504950, 806786626, 935873371, 1085613994, 1259313117,
  1460804100, 1694533640, 1965659907, 2280166376, 2644993880, 3068193785,
  3559105674, 4128563466, 4789134505, 5555396910, 6444261300, 7475343992,
  8671399915, 10058824785, 11668237635
];

const baseIncrement = [
  230, 257.6, 288.5, 323.1, 361.9, 405.3, 453.9, 508.4, 569.4, 637.7, 714.2,
  799.9, 895.9, 1003.4, 1123.8, 1258.7, 1409.7, 1578.9, 1768.4, 1980.6, 2218.3,
  2484.5, 2782.6, 3116.5, 3490.5, 3909.4, 4378.5, 4903.9, 5492.4, 6151.5,
  6889.7, 7716.5, 8642.5, 9679.6, 10841.2, 12142.1, 13599.2, 15231.1, 17058.8,
  19105.9, 21398.6, 23966.4, 26842.4, 30063.5, 33671.1, 37711.6, 42237, 47305.4,
  52982, 59339.8, 66460.6, 74435.9, 83368.2, 93372.4, 104577.1, 117126.4,
  131181.6, 146923.4, 164554.2, 184300.7, 206416.8, 231186.8, 258929.2,
  290000.7, 324800.8, 363776.9, 407430.1, 456321.7, 511080.3, 572409.9,
  641099.1, 718031, 804194.7, 900698.1, 1008781.9, 1129835.7, 1265416,
  1417265.9, 1587337.8, 1777818.3, 1991156.5, 2230095.3, 2497706.7, 2797431.5,
  3133123.3, 3509098.1, 3930189.9, 4401812.7, 4930030.2, 5521633.8, 6184229.9,
  6926337.5, 7757498, 8688397.8, 9731005.5, 10898726.2, 12206573.3, 13671362.1,
  15311925.6
];

const randomIncrement = [
  70, 78.4, 87.8, 98.3, 110.1, 123.3, 138.1, 154.7, 173.3, 194.1, 217.4, 243.5,
  272.7, 305.4, 342, 383, 429, 480.5, 538.2, 602.8, 675.1, 756.1, 846.8, 948.4,
  1062.2, 1189.7, 1332.5, 1492.4, 1671.5, 1872.1, 2096.8, 2348.4, 2630.2,
  2945.8, 3299.3, 3695.2, 4138.6, 4635.2, 5191.4, 5814.4, 6512.1, 7293.6,
  8168.8, 9149.1, 10247, 11476.6, 12853.8, 14396.3, 16123.9, 18058.8, 20225.9,
  22653, 25371.4, 28416, 31825.9, 35645, 39922.4, 44713.1, 50078.7, 56088.1,
  62818.7, 70356.9, 78799.7, 88255.7, 98846.4, 110708, 123993, 138872.2,
  155536.9, 174201.3, 195105.5, 218518.2, 244740.4, 274109.2, 307002.3,
  343842.6, 385103.7, 431316.1, 483074, 541042.9, 605968, 678684.2, 760126.3,
  851341.5, 953502.5, 1067922.8, 1196073.5, 1339602.3, 1500354.6, 1680397.2,
  1882044.9, 2107890.3, 2360837.1, 2644137.6, 2961434.1, 3316806.2, 3714822.9,
  4160601.6, 4659873.8
];

export const currentLevelFromXp = (xp: number): number => {
  if (xp < 100) return 1;

  const levelXp = levels.reduce((prev, value) => {
    if (xp < value && xp >= prev) return value;
    return prev;
  }, 0);
  if (!levelXp) return 100;
  return levels.indexOf(levelXp);
};

export const xpIncrementFromLevel = (level: number): number => {
  return (
    Math.round(baseIncrement[level - 1]) +
    Math.round(Math.random() * randomIncrement[level - 1])
  );
};
