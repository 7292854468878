export const longs = [
  -177.6, -175.2, -172.8, -170.4, -168, -165.6, -163.2, -160.8, -158.4, -156,
  -153.6, -151.2, -148.8, -146.4, -144, -141.6, -139.2, -136.8, -134.4, -132,
  -129.6, -127.2, -124.80000000000001, -122.4, -120, -117.6, -115.2, -112.8,
  -110.39999999999999, -108, -105.6, -103.2, -100.80000000000001,
  -98.39999999999999, -96, -93.60000000000001, -91.2, -88.8, -86.39999999999999,
  -84, -81.6, -79.2, -76.80000000000001, -74.4, -72, -69.6, -67.2, -64.8, -62.4,
  -60, -57.6, -55.199999999999996, -52.8, -50.400000000000006, -48, -45.6,
  -43.199999999999996, -40.8, -38.4, -36, -33.6, -31.200000000000003, -28.8,
  -26.4, -24, -21.599999999999998, -19.200000000000003, -16.8, -14.4, -12,
  -9.600000000000001, -7.2, -4.800000000000001, -2.4000000000000004, 0,
  2.4000000000000004, 4.800000000000001, 7.2, 9.600000000000001, 12, 14.4, 16.8,
  19.200000000000003, 21.599999999999998, 24, 26.4, 28.8, 31.200000000000003,
  33.6, 36, 38.400000000000006, 40.8, 43.199999999999996, 45.6, 48,
  50.400000000000006, 52.8, 55.199999999999996, 57.6, 60, 62.400000000000006,
  64.8, 67.2, 69.6, 72, 74.4, 76.80000000000001, 79.2, 81.6, 84,
  86.39999999999999, 88.8, 91.2, 93.60000000000001, 96, 98.39999999999999,
  100.80000000000001, 103.2, 105.6, 108, 110.39999999999999, 112.80000000000001,
  115.2, 117.6, 120, 122.4, 124.80000000000001, 127.2, 129.6, 132, 134.4, 136.8,
  139.2, 141.6, 144, 146.4, 148.8, 151.2, 153.60000000000002, 156, 158.4,
  160.79999999999998, 163.2, 165.6, 168, 170.4, 172.79999999999998,
  175.20000000000002, 177.6, 180
];

export const lats = [
  80, 76.10538869048075, 73.07179982330973, 70.49317237409409,
  68.20728291638945, 66.1297707038946, 64.21012379748919, 62.415264568692095,
  60.722138013743795, 59.11391644833739, 57.577872895760585, 56.1041056089231,
  54.68473114440743, 53.313351399058796, 51.984688863148975, 50.69432943599058,
  49.43853641360696, 48.21411296813442, 47.01829851381455, 45.84868928377072,
  44.70317654589901, 43.5798978951763, 42.4771983917884, 41.39359921709509,
  40.32777214305575, 39.27851854922363, 38.24475203469115, 37.22548389947128,
  36.2198109366426, 35.22690510067251, 34.24600471066893, 33.2764069182583,
  32.31746122424208, 31.368563870360283, 30.429152965435108, 29.498704231103645,
  28.57672727292079, 27.66276229904276, 26.75637722191123, 25.857165089041317,
  24.96474179771066, 24.07874405545799, 23.198827554148437, 22.32466533019597,
  21.455946287546595, 20.592373863372984, 19.733664819233383, 18.87954814280519,
  18.029764047291664, 17.184063057285243, 16.342205171302297,
  15.503959092424282, 14.66910151952365, 13.837416492447275, 13.008694785298417,
  12.182733342620821, 11.359334753859045, 10.538306761963426, 9.719461802435532,
  8.902616569479292, 8.087591606244956, 7.274210916429553, 6.462301594739216,
  5.651693473924993, 4.84221878628351, 4.033711837666388, 3.226008692171888,
  2.418946865802795, 1.6123650274637242, 0.8061027057453742, 0,
  -0.8061027057453742, -1.6123650274637242, -2.418946865802795,
  -3.226008692171888, -4.033711837666388, -4.84221878628351, -5.651693473924993,
  -6.462301594739216, -7.274210916429553, -8.087591606244956,
  -8.902616569479292, -9.719461802435532, -10.538306761963426,
  -11.359334753859045, -12.182733342620821, -13.008694785298417,
  -13.837416492447275, -14.66910151952365, -15.503959092424282,
  -16.342205171302297, -17.184063057285243, -18.029764047291664,
  -18.87954814280519, -19.733664819233383, -20.592373863372984,
  -21.455946287546595, -22.32466533019597, -23.198827554148437,
  -24.07874405545799, -24.96474179771066, -25.857165089041317,
  -26.75637722191123, -27.66276229904276, -28.57672727292079,
  -29.498704231103645, -30.429152965435108, -31.368563870360283,
  -32.31746122424208, -33.2764069182583, -34.24600471066893, -35.22690510067251,
  -36.2198109366426, -37.22548389947128, -38.24475203469115, -39.27851854922363,
  -40.32777214305575, -41.39359921709509, -42.4771983917884, -43.5798978951763,
  -44.70317654589901, -45.84868928377072, -47.01829851381455,
  -48.21411296813442, -49.43853641360696, -50.69432943599058,
  -51.984688863148975, -53.313351399058796, -54.68473114440743,
  -56.1041056089231, -57.577872895760585, -59.11391644833739,
  -60.722138013743795, -62.415264568692095, -64.21012379748919,
  -66.1297707038946, -68.20728291638945, -70.49317237409409, -73.07179982330973,
  -76.10538869048075, -80
];
