import React from 'react';

export const LockIcon = () => {
  return (
    <svg
      width="124"
      height="150"
      viewBox="0 0 124 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.0007 0C39.8388 0 21.8834 17.9554 21.8834 40.1172V61.999H10.9417C4.94302 61.999 0 66.942 0 72.9407V138.587C0 144.586 4.94302 149.529 10.9417 149.529H113.058C119.057 149.529 124 144.586 124 138.587V72.9407C124 66.942 119.057 61.999 113.058 61.999H102.117V40.1172C102.117 17.9554 84.1612 0 61.9993 0H62.0007ZM62.0007 7.29339C80.2471 7.29339 94.8238 21.8691 94.8238 40.1166V61.9984H29.1775V40.1166C29.1775 21.8701 43.7532 7.29339 62.0007 7.29339ZM10.9424 69.2927H113.059C115.142 69.2927 116.706 70.856 116.706 72.9394V138.586C116.706 140.669 115.142 142.232 113.059 142.232H10.9424C8.85887 142.232 7.29567 140.669 7.29567 138.586V72.9394C7.29567 70.8559 8.85894 69.2927 10.9424 69.2927ZM62.0007 83.8811C53.9873 83.8811 47.4122 90.4559 47.4122 98.4696C47.4122 102.046 48.9055 105.158 51.0589 107.702V116.705C51.0589 122.703 56.002 127.646 62.0007 127.646C67.9994 127.646 72.9424 122.703 72.9424 116.705V107.702C75.0958 105.158 76.5891 102.048 76.5891 98.4696C76.5891 90.4562 70.0143 83.8811 62.0007 83.8811ZM62.0007 91.1758C66.0723 91.1758 69.294 94.3977 69.294 98.4692C69.294 100.649 68.3426 102.541 66.8442 103.883C66.0734 104.582 65.6384 105.577 65.6473 106.618V116.704C65.6473 118.788 64.084 120.351 62.0006 120.351C59.9172 120.351 58.3539 118.788 58.3539 116.704V106.618C58.3628 105.577 57.9278 104.582 57.157 103.883C55.6585 102.541 54.7071 100.649 54.7071 98.4692C54.7071 94.3976 57.929 91.1758 62.0005 91.1758H62.0007Z"
        fill="white"
      />
    </svg>
  );
};
