import React from 'react';
import { WHITE } from '@global/styles/variables';

export const DexIcon = ({ fill = WHITE }: { fill: string }) => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7129 11.5424C12.7639 11.5669 12.8199 11.5795 12.8764 11.5789C12.9555 11.5789 13.0325 11.5534 13.0958 11.506L14.5582 10.4092C14.6502 10.3401 14.7044 10.2316 14.7044 10.1166C14.7044 10.0016 14.6502 9.89313 14.5582 9.82423L13.0958 8.72747C12.9851 8.64429 12.8368 8.63098 12.7129 8.69292C12.589 8.75487 12.5109 8.88148 12.5109 9.01985V9.76964C11.1241 9.86285 9.82444 10.4789 8.87436 11.4935C7.92443 12.5078 7.3947 13.8451 7.39258 15.235H8.12384C8.12576 14.0388 8.57833 12.8872 9.39139 12.0099C10.2045 11.1324 11.3182 10.5937 12.5109 10.5009V11.2134C12.5102 11.3528 12.5885 11.4803 12.713 11.5425L12.7129 11.5424ZM13.2419 9.75107L13.7292 10.1166L13.2419 10.4823V9.75107Z"
        fill={fill}
      />
      <path
        d="M20.3507 20.7568C20.2266 20.6956 20.0784 20.7097 19.968 20.7934L18.5057 21.8902C18.4136 21.9593 18.3594 22.0676 18.3594 22.1826C18.3594 22.2978 18.4136 22.4061 18.5057 22.4752L19.968 23.5719C20.0313 23.6194 20.1083 23.6451 20.1874 23.6451C20.2843 23.6451 20.3773 23.6065 20.4458 23.538C20.5143 23.4694 20.5529 23.3764 20.5529 23.2793V22.5278C21.9396 22.4346 23.2394 21.8186 24.1893 20.804C25.1394 19.7897 25.669 18.4524 25.6711 17.0625H24.94C24.9381 18.2587 24.4855 19.4103 23.6725 20.2876C22.8594 21.1651 21.7455 21.7037 20.5529 21.7966V21.084C20.5529 20.9455 20.4748 20.8187 20.3507 20.7567V20.7568ZM19.8217 22.5464L19.3344 22.1809L19.8217 21.8154V22.5464Z"
        fill={fill}
      />
      <circle cx="21.5" cy="10.8945" r="4.65" stroke={fill} strokeWidth="0.7" />
      <circle cx="11.5" cy="20.8945" r="4.65" stroke={fill} strokeWidth="0.7" />
    </svg>
  );
};
