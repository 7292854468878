import React from 'react';

export const UnmutedComponent: React.FC = () => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3146 1.00025L11.3193 1.00285C11.4404 1.06906 11.5154 1.19885 11.5161 1.33963L11.5161 1.33987V15.6655V15.6656H11.4661C11.4664 15.789 11.4019 15.9026 11.2977 15.9607C11.1936 16.0188 11.0672 16.0122 10.9693 15.9431L11.3146 1.00025ZM11.3146 1.00025C11.3071 0.995818 11.2995 0.991675 11.2918 0.987825L11.2437 0.961484L11.2438 0.967953L11.3146 1.00025ZM5.12349 11.4017H1.68669V5.60381H5.12349V11.4017ZM5.87933 5.42527L10.7753 2.05744V14.9479L5.87933 11.5801V5.42527Z"
        fill="#34FF61"
        stroke="#34FF61"
        strokeWidth="0.1"
      />
      <path
        d="M16.1279 2.51304V2.54191C15.9824 2.49703 15.822 2.54953 15.7284 2.67998L15.769 2.70912L15.7284 2.67994C15.6191 2.83206 15.6332 3.04507 15.7627 3.17974L15.7624 3.18001L15.7673 3.18392C17.3808 4.49269 18.3256 6.50839 18.3256 8.64504C18.3256 10.7814 17.3808 12.7974 15.7673 14.1058L15.767 14.1055L15.7627 14.1101C15.6333 14.245 15.619 14.458 15.7284 14.6101C15.8385 14.7632 16.0411 14.8092 16.203 14.7152L16.2033 14.7157L16.2094 14.7108C18.002 13.2556 19.05 11.0164 19.05 8.645C19.05 6.27324 18.002 4.03436 16.2094 2.57921L16.1279 2.51304Z"
        fill="#34FF61"
        stroke="#34FF61"
        strokeWidth="0.1"
      />
      <path
        d="M14.5626 3.76069V3.81455C14.422 3.75348 14.2558 3.80444 14.1707 3.94294C14.0756 4.09831 14.1188 4.30515 14.2674 4.40464L14.6404 3.8003C14.6397 3.79985 14.639 3.79941 14.6384 3.79897C14.614 3.78293 14.5886 3.77021 14.5626 3.76069ZM14.5626 3.76069C14.4036 3.70254 14.2221 3.76376 14.1281 3.91677L14.1281 3.91683C14.0194 4.09433 14.068 4.33129 14.2396 4.44619L14.2398 4.44633C15.6144 5.35698 16.4485 6.9405 16.4485 8.64412C16.4485 10.3473 15.6144 11.9313 14.2398 12.8416L14.2395 12.8418C14.068 12.957 14.0194 13.1936 14.1281 13.3711L14.1281 13.3711C14.2379 13.5502 14.467 13.6037 14.6402 13.488C16.2282 12.4351 17.1897 10.6066 17.1897 8.64194C17.1897 6.67763 16.2282 4.84905 14.6402 3.79616L14.5626 3.74469V3.76069Z"
        fill="#34FF61"
        stroke="#34FF61"
        strokeWidth="0.1"
      />
      <path
        d="M12.9086 5.09992C12.7143 5.02696 12.5026 5.1334 12.4343 5.33262L12.6749 11.5164C12.5086 11.5789 12.4219 11.7707 12.4816 11.9446L12.4343 11.9608C12.4343 11.9608 12.4343 11.9608 12.4343 11.9608C12.5026 12.16 12.7144 12.2665 12.9086 12.1935L12.9086 12.1935C13.6078 11.9302 14.2122 11.4482 14.6395 10.8136C15.0669 10.1791 15.297 9.42239 15.2988 8.64681L15.2793 8.64676L15.2988 8.64674V8.64669L15.2794 8.64667L15.2988 8.64662C15.297 7.87102 15.0669 7.11431 14.6395 6.47978C14.2121 5.84523 13.6078 5.36331 12.9086 5.09994L12.9086 5.09992Z"
        fill="#34FF61"
        stroke="#34FF61"
        strokeWidth="0.1"
      />
    </svg>
  );
};
