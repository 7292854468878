import React from 'react';
import { WHITE } from '@features/global/styles/variables';

export const Twitter = ({
  fill = WHITE,
  flag = false,
  onClick = () => {}
}: {
  fill: string;
  flag?: boolean;
  onClick?: () => void;
}) => {
  const twitterClassName = flag
    ? 'pointerSvgUserInfo'
    : 'notAllowedSvgUserInfo';
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={twitterClassName}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2474 4.20616C17.2704 4.30216 17.2834 4.45616 17.2834 4.67116C17.2834 6.38516 16.8664 8.04016 16.0334 9.63516C15.1774 11.3252 13.9744 12.6702 12.4274 13.6692C10.7364 14.7882 8.79642 15.3482 6.60642 15.3482C4.51142 15.3482 2.59542 14.7882 0.857422 13.6692C1.11942 13.6932 1.41642 13.7052 1.75042 13.7052C3.48742 13.7052 5.04742 13.1702 6.42842 12.0992C5.59542 12.0992 4.86242 11.8542 4.23142 11.3662C3.60142 10.8792 3.16642 10.2662 2.92942 9.52716C3.16642 9.55116 3.39342 9.56216 3.60642 9.56216C3.94042 9.56216 4.27242 9.52716 4.60642 9.45616C3.74942 9.26616 3.03542 8.82516 2.46442 8.13516C1.89342 7.44416 1.60742 6.65916 1.60742 5.77716V5.70616C2.13042 6.01616 2.69042 6.18316 3.28542 6.20616C2.78542 5.85016 2.38042 5.39716 2.07142 4.85016C1.76242 4.30216 1.60742 3.70116 1.60742 3.04616C1.60742 2.39216 1.78642 1.76716 2.14342 1.17116C3.07142 2.33816 4.20842 3.26716 5.55342 3.95716C6.89742 4.64716 8.33242 5.02716 9.85542 5.10016C9.80842 4.81316 9.78442 4.52816 9.78442 4.24216C9.78442 3.57616 9.95042 2.95116 10.2844 2.36716C10.6174 1.78416 11.0694 1.32616 11.6424 0.993164C12.2134 0.659164 12.8314 0.493164 13.4984 0.493164C14.0464 0.493164 14.5524 0.600164 15.0164 0.814164C15.4794 1.02816 15.8914 1.31416 16.2484 1.67116C17.1044 1.50516 17.9024 1.20716 18.6404 0.778164C18.3544 1.65916 17.8074 2.35016 16.9974 2.85016C17.7124 2.75516 18.4264 2.55216 19.1404 2.24216C18.6164 3.00516 17.9854 3.65916 17.2474 4.20616Z"
        fill={fill}
      />
    </svg>
  );
};
