import React from 'react';

export const WalletIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.392 2.48715L1.9847 2.48752C1.72551 2.48752 1.51262 2.2723 1.51219 2.00593L1.46223 2.006H1.51219C1.51219 1.73829 1.72528 1.52247 1.9847 1.52247H13.7687C13.9245 1.52247 14.05 1.39352 14.05 1.23642C14.05 1.07944 13.9247 0.95 13.7687 0.95H1.9847C1.4134 0.95 0.95 1.42436 0.95 2.00519V12.3797C0.95 12.7481 1.24397 13.05 1.60738 13.05H13.392C13.7555 13.05 14.0499 12.7481 14.0499 12.3797V3.15739C14.0499 2.78901 13.7555 2.48714 13.392 2.48714V2.48715ZM5.87505 3.05961L5.92505 3.05961V3.05924H13.4874L13.4874 5.94565H8.87299C8.50917 5.94565 8.21515 6.24754 8.21515 6.61591V8.92183C8.21515 9.29019 8.50915 9.59208 8.87299 9.59208H13.4874V12.478L1.51233 12.4779V2.95323L1.63928 2.99907C1.74985 3.03909 1.86633 3.05952 1.98482 3.05952L5.87505 3.05961ZM13.4874 9.01993H8.7779V6.51776H13.4874V9.01993Z"
        fill="#34FF61"
        stroke="#34FF61"
        strokeWidth="0.1"
      />
    </svg>
  );
};
